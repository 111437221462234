<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <Form v-if="model"
                :submit-text="pageTitle"
                :model="model"
                :is-edit="true"
                @on-submit="createModel" />
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import Form from './_EditForm'
import OfficialService from '@admin/services/OfficialService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  components: { PlaceholderForm, Form },
  data () {
    return {
      id: 0,
      pageTitle: '编辑Banner',
      model: null
    }
  },
  async created () {
    let id = this.$router.currentRoute.query.id
    this.id = id
    const { data } = await flatry(OfficialService.edit(id))
    if (data) {
      this.model = data.data
    }
  },

  methods: {
    async createModel (model, success, callback) {
      const { data } = await flatry(OfficialService.edit(this.id, model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/official', query: { id: data.data } })

        success()
      }

      callback()
    }
  }
}
</script>
